import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout';
import HeaderMeta from '../components/HeaderMeta';

import {
  BasicCard,
  BasicGrid,
  BodyBox,
  Button,
  ColorList,
  FlexColumn,
  FlexRow,
  HeadingBox,
  HomePageVennDiagram,
  LinkBox,
  MinwareOctopusStack,
  responsiveBreakpoints,
  ResponsiveComparison,
  ResponsiveComparisonParagraph,
  ResponsiveComparisonText,
  ResponsiveFigure,
  ResponsiveImage,
  ResponsiveMaxWidthFrame,
  SectionGroup,
  SubHeadlineBox,
  TitleBox,
  vectors,
} from "@minware/ui-minware";

const {
  graphLink,
  valueStream,
  lineChart,
  listCheck,
  scorecardGraphic,
  githubLogo,
  gitlabLogo,
  azureDevopsLogo,
  bitbucketLogo,
  jiraLogo,
  googleCalendarLogo,
  trophy,
  book,
  check,
  historyCircle,
} = vectors;

const wideBreakpoint = 800;
const inputLogos = [
  {
    label: 'GitHub',
    vector: githubLogo,
  },
  {
    label: 'GitLab',
    vector: gitlabLogo,
  },
  {
    label: 'Azure',
    vector: azureDevopsLogo,
  },
  {
    label: 'Bitbucket',
    vector: bitbucketLogo,
  },
  {
    label: 'Jira',
    vector: jiraLogo,
  },
  {
    label: 'Google',
    vector: googleCalendarLogo,
  },
];

const stackItems = [
  {
    label: 'Data Quality',
    vector: check,
    color: ColorList.green100,
  },
  {
    label: 'Code/Ticket Linking',
    vector: graphLink,
    color: ColorList.cyan100,
  },
  {
    label: 'Change History',
    vector: historyCircle,
    color: ColorList.purple100,
  },
  {
    label: 'Work Timeline',
    vector: valueStream,
    color: ColorList.magenta100,
  },
];
const outputReports = [
  {
    label: 'Report Library',
    vector: book,
  },
  {
    label: 'Custom Reports',
    vector: lineChart,
  },
  {
    label: 'Scorecard Metrics',
    vector: listCheck,
  },
  {
    label: 'Benchmarks',
    vector: trophy,
  },
];

const octopusProps = {
  inputLogos,
  stackItems,
  outputReports,
  wideBreakpoint,
  bottomText: 'Observability Platform',
};

const diagramProps = {
  topHeading: { label: 'Tickets', icon: vectors.jiraStory },
  middleHeading: 'Observability',
  middleItems: [
    { label: 'Ticket Dev Time', icon: vectors.timerPerson },
    { label: 'Bug/Incident Interruptions', icon: vectors.warning },
    { label: 'Ticket → Deploy Cycle Time', icon: vectors.timer },
    { label: 'Context Switching', icon: vectors.leftRightArrow },
    { label: 'Tech Debt', icon: vectors.moneyDollarCircle },
  ],
  bottomHeading: { label: 'Code', icon: vectors.merge },
  wideBreakpoint,
};

export const IndexPageTemplate = ({
  header,
  title,
  subheading,
  ctaText,
  ctaLink,
  secondCtaText,
  secondCtaLink,
  topQuote,
  mainSection,
  fundamentals,
  scorecardSection,
  impactSection,
  impact,
  octopus
}) => {
  octopus = octopus || {};

  const headerWithCanon = {
    ...header,
    canonicalUrl: '',
  };

  return (
    <Layout desktopBgImageName="homeDesktop">
      <HeaderMeta header={headerWithCanon}/>
      <ResponsiveMaxWidthFrame maxWidth="600px" spacing={20}>
        <SectionGroup>
          <TitleBox>{title}</TitleBox>
          <SubHeadlineBox centered isMain>
          {subheading}
          </SubHeadlineBox>
        </SectionGroup>
        <FlexRow gap={20}>
          <Button cta link={ctaLink}>
            {ctaText}
          </Button>
          <Button ctaSecondary link={secondCtaLink}>
            {secondCtaText}
          </Button>
        </FlexRow>
        <HomePageVennDiagram {...diagramProps} />
      </ResponsiveMaxWidthFrame>


      <ResponsiveMaxWidthFrame maxWidth="800px">
        <SectionGroup showBackground>
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="20px 0 10px 0"
          >
            Get every report imaginable, out of the box
          </HeadingBox>
          <SubHeadlineBox centered>
            🛑🍒 Stop cherry-picking data from scattered tools. 🍒🛑
          </SubHeadlineBox>
          <SubHeadlineBox centered>
            <div>
              Making reports is so easy with{' '}
              <LinkBox inline link="/docs/overview/minql" size="body">
                minQL
              </LinkBox>{'  '}
              (5-10 minutes), we built everything we could think of.
            </div>
          </SubHeadlineBox>
          <SubHeadlineBox centered>
            <div>
              <i>Something missing?</i> We'll add it for free during your trial.
            </div>
          </SubHeadlineBox>
        </SectionGroup>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="1060px" spacing={20}>
        <SectionGroup>
          <HeadingBox
            level="h2"
            align="center"
            justifyContent="center"
            forceMargin="10px 0"
          >
            Browse most popular report topics:
          </HeadingBox>
        </SectionGroup>

        <BasicGrid gap={30} rowGap={40} minColWidth={210}>
          <BasicCard link="/reports/scrum-and-agile" minWidth={180}>
            <ResponsiveImage
              src="/img/home-agile.png"
              title="Agile Report Screenshot"
              origWidth={561}
              origHeight={400}
              minWidth={180}
              lrPad={40}
            />
            <FlexColumn gap={5}>
              <HeadingBox level="h3" noMargin>
                Agile
              </HeadingBox>
              <BodyBox noMargin>
                Scrum sprint completion, estimate accuracy, lead time, kanban work-in-progress, and
                more.
              </BodyBox>
            </FlexColumn>
          </BasicCard>
          <BasicCard link="/reports/project-cost" minWidth={180}>
            <ResponsiveImage
              src="/img/home-cost.png"
              title="Project/Cost Report Screenshot"
              origWidth={561}
              origHeight={400}
              minWidth={180}
              lrPad={40}
            />
            <FlexColumn gap={5}>
              <HeadingBox level="h3" noMargin>
                Project/Cost
              </HeadingBox>
              <BodyBox noMargin>
                Dev time, cost capitalization, R&D credits, project completion, and more.
              </BodyBox>
            </FlexColumn>
          </BasicCard>
          <BasicCard link="/reports/quality" minWidth={180}>
            <ResponsiveImage
              src="/img/home-quality.png"
              title="Quality Report Screenshot"
              origWidth={561}
              origHeight={400}
              minWidth={180}
              lrPad={40}
            />
            <FlexColumn gap={5}>
              <HeadingBox level="h3" noMargin>
                Quality
              </HeadingBox>
              <BodyBox noMargin>
                Bug SLAs (MTTR), change failure rate, review thoroughness, total bug load, and more.
              </BodyBox>
            </FlexColumn>
          </BasicCard>
          <BasicCard link="/reports/workflow" minWidth={180}>
            <ResponsiveImage
              src="/img/home-workflow.png"
              title="Workflow Report Screenshot"
              origWidth={561}
              origHeight={400}
              minWidth={180}
              lrPad={40}
            />
            <FlexColumn gap={5}>
              <HeadingBox level="h3" noMargin>
                Workflow
              </HeadingBox>
              <BodyBox noMargin>
                PR cycle times, ticket lead times, time in status, context switching, flow
                efficiency, and more.
              </BodyBox>
            </FlexColumn>
          </BasicCard>
        </BasicGrid>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="1080px">
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          Customize anything and everything with minQL
        </HeadingBox>
        <ResponsiveComparison breakpoint={responsiveBreakpoints.multiCol} evenMarginTop>
          <ResponsiveComparisonText
            leftBarColor={ColorList.blue100}
            breakpoint={responsiveBreakpoints.multiCol}
          >
            <ResponsiveComparisonParagraph>
              All out-of-the-box reports are built on top of the{'  '}
              <LinkBox inline link="/docs/overview/minql" size="body">
                minQL
              </LinkBox>{' '}
              query language and fully editable.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              Access any field from any data source to create custom metrics with powerful formulas,
              including custom event cycle times.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              No spreadsheets.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              No SQL.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              No Jira changes required.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline noFlex link="/org/demo/dashboard/ae061875-f72c-42a6-b999-ce54d9921df2"
                size="body">See Report Builder Live &gt;</LinkBox>
            </ResponsiveComparisonParagraph>
          </ResponsiveComparisonText>
          <ResponsiveFigure
            maxWidth={1000}
            image="/img/ss-custom-report-ui-orig.png"
            aspect={1.6}
            flex={3.2}
            pad={0}
          />
        </ResponsiveComparison>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="1080px">
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          Set goals and track benchmarks
        </HeadingBox>
        <ResponsiveComparison
          breakpoint={responsiveBreakpoints.multiCol}
          reverse
          evenMarginTop
        >
          <ResponsiveComparisonText
            leftBarColor={ColorList.green100}
            breakpoint={responsiveBreakpoints.multiCol}
          >
            <ResponsiveComparisonParagraph>
              Set goals and track progress toward predictable software delivery with minware’s
              library of scorecard metrics.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              Measure code and ticket best practices like using pull requests, thorough code
              reviews, linking branches to tickets, and more.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline noFlex link="/org/demo/dashboard/c71ae801-4335-485e-a336-ee2683d3a5bd"
                  size="body">
                See Metric Scorecard Live &gt;
              </LinkBox>
            </ResponsiveComparisonParagraph>
          </ResponsiveComparisonText>
          <ResponsiveFigure
            maxWidth={400}
            image={scorecardGraphic()}
            aspect={1.7}
            pad={0}
            flex={1}
          />
        </ResponsiveComparison>
      </ResponsiveMaxWidthFrame>


      <ResponsiveMaxWidthFrame maxWidth="800px">
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          How does it work?
        </HeadingBox>
        <MinwareOctopusStack {...octopusProps} />
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="800px" spacing={40}>
        <SectionGroup>
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="30px 0 10px 0"
          >
            Clean, full-time-series data
          </HeadingBox>
          <SubHeadlineBox centered>
            minware does all the hard work for you – loading data, tracing commit graphs, and
            linking code/tickets to build a full-time-series data set (accessible from anywhere with
            minQL, of course) so you can view your data at any point in the past, and see how each
            person spent their time with auditable precision.
            <LinkBox inline noFlex link="/stack/time-attribution" size="body">
              Learn more about minware’s patent-pending time model &gt;
            </LinkBox>
            <LinkBox inline noFlex link="/docs/use-cases/capitalization" size="body">
              Read about cost capitalization with minware &gt;
            </LinkBox>
          </SubHeadlineBox>
        </SectionGroup>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="600px" spacing={20}>
        <SectionGroup>
          <HeadingBox level="h0" tagLevel="h2" align="center" justifyContent="center">
            Try minware today
          </HeadingBox>
        </SectionGroup>
        <FlexRow gap={20}>
          <Button cta link={ctaLink}>
            {ctaText}
          </Button>
          <Button ctaSecondary link={secondCtaLink}>
            {secondCtaText}
          </Button>
        </FlexRow>
      </ResponsiveMaxWidthFrame>
    </Layout>
  )
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <IndexPageTemplate
      {...frontmatter}
    />
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      ...HeaderMeta
      ...OctopusFragment
      frontmatter {
        title
        subheading
        ctaText
        ctaLink
        secondCtaText
        secondCtaLink
      }
    }
  }
`
